// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("popper.js")
require("bootstrap")
require("select2")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

class addFields {
    // This executes when the function is instantiated.
    constructor() {
      this.links = document.querySelectorAll('.add_fields')
      this.iterateLinks()
    }
  
    iterateLinks() {
      // If there are no links on the page, stop the function from executing.
      if (this.links.length === 0) return
      // Loop over each link on the page. A page could have multiple nested forms.
      this.links.forEach(link => {
        link.addEventListener('click', e => {
          this.handleClick(link, e)
        })
      })
    }
  
    handleClick(link, e) {
      // Stop the function from executing if a link or event were not passed into the function.
      if (!link || !e) return
      // Prevent the browser from following the URL.
      e.preventDefault()
      // Save a unique timestamp to ensure the key of the associated array is unique.
      let time = new Date().getTime()
      // Save the data id attribute into a variable. This corresponds to `new_object.object_id`.
      let linkId = link.dataset.id
      // Create a new regular expression needed to find any instance of the `new_object.object_id` used in the fields data attribute if there's a value in `linkId`.
      let regexp = linkId ? new RegExp(linkId, 'g') : null
      // Replace all instances of the `new_object.object_id` with `time`, and save markup into a variable if there's a value in `regexp`.
      let newFields = regexp ? link.dataset.fields.replace(regexp, time) : null
      // Add the new markup to the form if there are fields to add.
      newFields ? link.insertAdjacentHTML('beforebegin', newFields) : null
    }
}
  
class removeFields {
    // This executes when the function is instantiated.
    constructor() {
      this.iterateLinks()
    }
  
    iterateLinks() {
      // Use event delegation to ensure any fields added after the page loads are captured.
      document.addEventListener('click', e => {
        if (e.target && e.target.className == 'remove_fields') {
          this.handleClick(e.target, e)
        }
      })
    }
  
    handleClick(link, e) {
      // Stop the function from executing if a link or event were not passed into the function.
      if (!link || !e) return
      // Prevent the browser from following the URL.
      e.preventDefault()
      // Find the parent wrapper for the set of nested fields.
      let fieldParent = link.closest('.nested-fields')
      // If there is a parent wrapper, find the hidden delete field.
      let deleteField = fieldParent
        ? fieldParent.querySelector('input[name*=_destroy]')
        : null
      // If there is a delete field, update the value to `1` and hide the corresponding nested fields.
      if (deleteField) {
        deleteField.value = 1
        fieldParent.style.display = 'none'
      }
    }
}

document.addEventListener('turbolinks:load', () => {
    new addFields();
    new removeFields();
    ['supplier', 'importer', 'exporter'].forEach((kind) => {
        if ($(`#clearance_${kind}_name`)) {
            $(`#clearance_${kind}_name`).select2({
                theme: 'bootstrap4',
                placeholder: 'Search',
                minimumInputLength: 1,
                ajax: {
                    url: `/contractors?kind=${kind}`,
                    dataType: 'json'
                }
            })
            $(`#clearance_${kind}_name`).on('select2:select', (e) => {
                $(`#clearance_${kind}_address_line1`).val(e.params.data.addressLine1)
                $(`#clearance_${kind}_address_line2`).val(e.params.data.addressLine2)
                $(`#clearance_${kind}_city`).val(e.params.data.city)
                $(`#clearance_${kind}_country`).val(e.params.data.country)
                $(`#clearance_${kind}_country_code`).val(e.params.data.countryCode)
                $(`#clearance_${kind}_eori_number`).val(e.params.data.eoriNumber)
            })
        }
    });

    if ($('#clearance_order_number')) {
        $('#clearance_order_number').select2({
            theme: 'bootstrap4',
            placeholder: 'Search',
            minimumInputLength: 1,
            allowClear: true,
            ajax: {
                url: '/invoice_details',
                dataType: 'json'
            }
        })
        $('#clearance_order_number').on('select2:select', (e) => {
            $('#clearance_invoice_number').val('').attr('disabled', true)
            $('#clearance_invoice_inco_term').val(e.params.data.incoTerm)
            $('#clearance_invoice_inco_term').trigger('change')
            $('#clearance_invoice_amount').val(e.params.data.totalAmount)
            $('#clearance_invoice_currency').val(e.params.data.currencyCode)
            $('#clearance_invoice_currency').trigger('change')
            $('#clearance_invoice_date').val(e.params.data.invoiceDate)
            $('#clearance_invoice_net_weight').val(e.params.data.totalNetWeight)
            $('#clearance_customer_name').val(e.params.data.customerName)
            $('#clearance_customer_address_line1').val(e.params.data.customerAddressLine1)
            $('#clearance_customer_address_line2').val(e.params.data.customerAddressLine2)
            $('#clearance_customer_city').val(e.params.data.customerCity)
            $('#clearance_customer_country').val(e.params.data.customerCountry)
            
            document.querySelectorAll('.remove_fields').forEach((f) => f.click())

            e.params.data.items.forEach((item, index) => {
                document.querySelector('.add_fields').click()
                let container = $('.clearance-item:last');
                container.find('input[name*=commodity_code]').val(item.commodityCode)
                container.find('input[name*=commodity_description]').val(item.commodityDescription)
                container.find('input[name*=net_weight]').val(item.netWeight)
                container.find('input[name*=net_value]').val(item.netValue)
                container.find('input[name*=duty_rate]').val(item.dutyRate)
            })
        })
        $('#clearance_order_number').on('select2:unselect', (e) => {
            $('#clearance_invoice_number').val('').attr('disabled', false)
            document.querySelectorAll('.remove_fields').forEach((f) => f.click())
        })
    }

    [
        'invoice_inco_term',
        'invoice_currency',
        'freight_cost_currency',
        'insurance_cost_currency',
        'other_cost_currency',
        'loading_cost_currency',
        'invoice_net_weight_unit',
        'invoice_gross_weight_unit',
        'invoice_total_volume_unit',
        'invoice_packs_unit',
        'transport_mode',
        'transport_type'
    ].forEach((field) => {
        if ($(`#clearance_${field}`)) {
            $(`#clearance_${field}`).select2({ theme: 'bootstrap4' })
        }
    })

    $(".custom-file-input").on("change", function() {
        let fileName = $(this).val().split("\\").pop();
        $(this).siblings(".custom-file-label").addClass("selected").html(fileName);
    });
})
